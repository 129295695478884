import React, { useState } from 'react';
import '../styles/AutoContainer.css';
import PrettyTable from './PrettyTable';
import { Button } from '@mui/material';
import { calculatePathTotalDistance } from '../utils/Calculations';
import { isEmpty, get } from 'lodash';


/**
 * AutoContainer component.
 * @return {JSX.Element}
 */
export default function AutoContainer() {
  const [sensorData, setSensorData] = useState({
    ori: { name: 'Orientação', x: 0.0, y: 0.0, z: 0.0, units: 'º' },
    acc: { name: 'Acelerómetro', x: 0.0, y: 0.0, z: 0.0, units: 'm/s²' },
    gps: { name: 'GPS', x: 0.0, y: 0.0, z: 0.0, units: 'º' }
  });
  const [measurements, setMeasurements] = useState({
    currSpeed: { name: 'Velocidade Instantânea', value: 0.0, units: 'kmh' },
    timeOneHundred: { name: 'Tempo [0kmh aos 100kmh]', value: 0.0, units: 's' },
    timeTowHundred: { name: 'Tempo [100kmh aos 200kmh]', value: 0.0, units: 's' },
    distOneHundred: { name: 'Velocidade aos 100m', value: 0.0, units: 'kmh' },
    maxVel: { name: 'Velocidade Máxima', value: 0.0, units: 'kmh' },
    maxAcc: { name: 'Aceleração Máxima', value: 0.0, units: 'ms²' },
    totDist: { name: 'Distancia Percorrida', value: 0.0, units: 'm' }
  });
  const [readingValues, setReadingValues] = useState(false);
  const [gpsWatchId, setGpsWatchId] = useState(0);
  const [startCoords, setStartCoords] = useState({})
  const [pathCoords, setPathCoords] = useState([])


  /**
   * Updates the current measurements map with gps data
   * @param {coords} coords
   */
  function updateGPSValues(coords) {
    setSensorData(prevState => ({
      ...prevState,
      gps: {
        ...prevState.gps,
        x: coords.latitude ?
            coords.latitude :
            prevState.gps.x,
        y: coords.longitude ?
            coords.longitude :
            prevState.gps.y,
        z: coords.altitude ?
            Math.round(coords.altitude) :
            prevState.gps.z,
      },
    }));
  }

  /**
   * Handles logic when gps values update
   * @param {coords} coords
   */
  function handleGPSPositionChange({ coords }) {
    updateGPSValues(coords);

    if (pathCoords.length > 0) {
      // we have already started counting for a race
      // add new entry to path coords
      setPathCoords(prevPath => ([
          ...prevPath,
          {
            lat: sensorData.gps.x,
            lon: sensorData.gps.y,
            alt: sensorData.gps.z
          }
      ]));

      // update distance
      setMeasurements(prevValue => ({
        ...prevValue,
        totDist: {
          ...prevValue.totDist,
          value: calculatePathTotalDistance(pathCoords)
        },
        timeOneHundred: {
          ...prevValue.timeOneHundred,
          value: ((Date.now() / 1000) - pathCoords[0].time)
        },
        timeTowHundred: {
          ...prevValue.timeTowHundred,
          value: ((Date.now() / 1000) - pathCoords[0].time)
        }
      }));
    }
  }


  /**
   * Handles device motion gyro and accelerometer events
   * @param {event} event
   */
  function handleMotion({ acceleration }) {
    if (
        isEmpty(startCoords)
        && acceleration
        && acceleration.z
        && Math.abs(Math.round(acceleration.z)) > 2
    ) {
      setStartCoords({
        lat: sensorData.gps.x,
        lon: sensorData.gps.y,
        alt: Math.round(sensorData.gps.z),
        time: Date.now() / 1000
      });
      setPathCoords([startCoords]);
    }

    setSensorData(prevState => {
      return {
        ...prevState,
        acc: {
          ...prevState.acc,
          x: get(acceleration, ['x'], prevState.acc.x).toFixed(1),
          y: get(acceleration, ['y'], prevState.acc.y).toFixed(1),
          z: get(acceleration, ['z'], prevState.acc.z).toFixed(1)
        }
      }
    })
  }


  /**
   * Handles device orientation event changes
   * @param {*} event
   */
  function handleOrientation(event) {
    setSensorData(prevState => {
      return {
        ...prevState,
        ori: {
          ...prevState.ori,
          x: event.alpha ?
              Math.round(event.alpha) :
              prevState.ori.x,
          y: event.beta ?
              Math.round(event.beta) :
              prevState.ori.y,
          z: event.gamma ?
              Math.round(event.gamma) :
              prevState.ori.z,
        }
      }
    })
  }


  const handleStartCapture = (e) => {
    e.preventDefault();

    // Request permission for iOS 13+ devices
    if (DeviceMotionEvent &&
      typeof DeviceMotionEvent.requestPermission === 'function') {
      DeviceMotionEvent.requestPermission().then(console.log);
    }

    if (!readingValues) {
      console.debug('A iniciar leitura de sensores');
      window.addEventListener('devicemotion', handleMotion);
      window.addEventListener('deviceorientation', handleOrientation);
      let watchId = navigator.geolocation.watchPosition(
          handleGPSPositionChange,
          console.log,
          {
            enableHighAccuracy: true,
            maximumAge: 30000,
            timeout: 27000,
          },
      );
      setGpsWatchId(watchId);
      setReadingValues(true);
    } else {
      console.debug('A parar leitura de sensores');
      window.removeEventListener('devicemotion', handleMotion);
      window.removeEventListener('deviceorientation', handleOrientation);
      navigator.geolocation.clearWatch(gpsWatchId);
      setReadingValues(false);
    }
  }


  return (
    <div className='AutoContainerDiv'>
      { !readingValues &&
      <div className='StartReadingButtonDiv'>
        <Button
            variant='contained'
            onClick={handleStartCapture}
        >
          Iniciar leitura de sensores
        </Button>
      </div>}

      <div className='CurrentSensorDataTableDiv'>
        <PrettyTable rows={sensorData} cols={['name', 'x', 'y', 'z']}/>
      </div>

      <div className='PredictionDataTableDiv'>
        <PrettyTable rows={measurements} cols={['name', 'value', 'units']} hasHeader={false}/>
      </div>
    </div>
  )
};
