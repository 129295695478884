function numberToRad(number) {
    return number * Math.PI / 180;
}

function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = numberToRad(lat2 - lat1);
    const dLon = numberToRad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(numberToRad(lat1)) * Math.cos(numberToRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
}


function calculatePathTotalDistance(points) {
    if (points.length < 2) {
        throw new Error('Minimum 2 coordinates required')
    }

    let accDistance = 0;
    for (let i = 0; i < points.length - 1; i++) {
        for (let j = 1; j < points.length; j++) {
            accDistance += calculateDistance(points[i].lat, points[i].lon, points[j].lat, points[j].lon);
        }
    }

    return accDistance;
}


module.exports = {
    calculatePathTotalDistance
}