import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


/**
 * @param {object} props
 * @return {JSX.Element}
 */
export default function PrettyTable(props) {
  const { rows, cols, hasHeader } = props;


  return (
    <TableContainer component={Paper}>
      <Table style={{ width: '100%' }}>
        {
          hasHeader && <TableHead>
            <TableRow>
              <StyledTableCell>Medida</StyledTableCell>
              <StyledTableCell align="right">X</StyledTableCell>
              <StyledTableCell align="right">Y</StyledTableCell>
              <StyledTableCell align="right">Z</StyledTableCell>
            </TableRow>
          </TableHead>
        }
        <TableBody>
          {Object.keys(rows).map((id) => (
            <StyledTableRow key={id}>
              {cols.map((colName, index) => {
                if (index === 0) {
                  return <StyledTableCell component="th" scope="row">{rows[id][colName]}</StyledTableCell>;
                }
                else {
                  return <StyledTableCell align="right">{rows[id][colName]}</StyledTableCell>;
                }
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

PrettyTable.defaultProps = {
  hasHeader: true
}

PrettyTable.propTypes = {
  rows: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasHeader: PropTypes.bool
};
