import './styles/App.css';
import React from 'react';
// import SwitchSelector from 'react-switch-selector';
import AutoContainer from './components/AutoContainer';
// import ManualContainer from './components/ManualContainer';


/**
 * Global App component.
 * @return {JSX.Element}
 */
export default function App() {
  // const [isAuto, setIsAuto] = useState(true);

  // const options = [
  //   {
  //     label: 'Auto',
  //     value: {
  //       auto: true,
  //     },
  //     selectedBackgroundColor: '#176B87',
  //   },
  //   {
  //     label: 'Manual',
  //     value: {
  //       auto: false,
  //     },
  //     selectedBackgroundColor: '#9EB23B',
  //   },
  // ];
  // const initialSelectedIndex = options
  //     .findIndex(({ label }) => label === 'Auto');


  // const changeHandler = ({ auto }) => {
  //   setIsAuto(auto);
  // };


  return (
    <div className='App'>
      {/*<h3 id='mainTitle'>Modo de funcionamento</h3>*/}

      {/*<div id='manualAutoToggleDiv'>*/}
      {/*  <SwitchSelector*/}
      {/*    onChange={changeHandler}*/}
      {/*    options={options}*/}
      {/*    initialSelectedIndex={initialSelectedIndex}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*{ isAuto && <AutoContainer /> }*/}
      <AutoContainer />
      {/*{ !isAuto && <ManualContainer /> }*/}
    </div>
  );
}
